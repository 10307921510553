import "../common/common.css"

const Discography = () => {

  return (
    <div>
      <h1>作成中。。。</h1>
    </div>
  )
}
export default Discography